import React from 'react';
import { Container } from 'constate';

import { authEffects } from '../../app/effects/auth';

const NoAuthError = ({logout}) => (
  <div>

    <div className="row pt3">
      <div className="col-xs-1 col-sm-1 col-md-2 col-lg-2"></div>
      <div className="col-xs-10 col-sm-10 col-md-2 col-lg-8">
        <h1>Error</h1>
      </div>
    </div>

    <div>
      <div className="row">
        <div className="col-xs-1 col-sm-1 col-md-2 col-lg-2"></div>
        <div className="col-xs-10 col-sm-10 col-md-2 col-lg-8">
          <p>Oops, that wasn&#39;t supposed to happen</p>
        </div>
      </div>

      <div className="row pb3">
        <div className="col-xs-1 col-sm-1 col-md-2 col-lg-2"></div>
        <div className="col-xs-10 col-sm-10 col-md-2 col-lg-8">
          <button className='bg-yellow' type="submit" onClick={logout}>Logout</button>
        </div>
      </div>
    </div>

  </div>
)

const AuthError = ({logout}) => (
  <div>

    <div className="row pt3">
      <div className="col-xs-1 col-sm-1 col-md-2 col-lg-2"></div>
      <div className="col-xs-10 col-sm-10 col-md-2 col-lg-8">
        <h1>Not Authenticated</h1>
      </div>
    </div>

    <div>
      <div className="row">
        <div className="col-xs-1 col-sm-1 col-md-2 col-lg-2"></div>
        <div className="col-xs-10 col-sm-10 col-md-2 col-lg-8">
          <p>You must log in to see this</p>
        </div>
      </div>

      <div className="row pb3">
        <div className="col-xs-1 col-sm-1 col-md-2 col-lg-2"></div>
        <div className="col-xs-10 col-sm-10 col-md-2 col-lg-8">
          <button className='bg-yellow' type="submit" onClick={logout}>Continue</button>
        </div>
      </div>
    </div>

  </div>
)

const ErrorCatcher = ({authIssue, logout}) =>
  <div className="pb4-ns">
    { authIssue && <AuthError logout={logout}/> }
    { !authIssue && <NoAuthError logout={logout}/> }
  </div>

const ErrorPage = ({error, metaData}) => (
    <Container
      context='primary'
      effects={authEffects}
      >
      {/* {({logout, bugsnagClient}) => { */}
      {({logout}) => {

        let authIssue = false;

        if (error) {

          authIssue = error.toString().includes('401');

          // if (!authIssue) {
          //   if (bugsnagClient) {
          //     bugsnagClient.notify(error, {
          //       metaData
          //     });
          //   }
          // }
        }

        return (<ErrorCatcher authIssue={authIssue} logout={logout} />);
      }}
    </Container>
)

export default ErrorPage;