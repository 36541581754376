import { SettingsQuery } from '../graph/queries/SettingsQuery';
import { RequestMandateMutation } from '../graph/mutations/RequestMandateMutation';
import { DeactivateBillingMutation } from '../graph/mutations/DeactivateBillingMutation';

import { accountId, retryAccountId } from '../graph/cache/account';


export const requestMandateRemote = async (client) => {

  let { account, error } = accountId(client);

  if (error) {
    ({ account, error } = await retryAccountId(client));
    if (error) {
      return { error };
    }
  }

  const request = {
    mutation: RequestMandateMutation,
    variables: {
      account,
    }
  };

  return client.mutate(request)
  .then(({data, error}) => ({ data, error }))
  .catch((error) => ({ error }));
}

export const deactivateBilling = (client, account, effectiveDate) => {

  const request = {
    mutation: DeactivateBillingMutation,
    variables: {
      account, effectiveDate,
    },
    refetchQueries: [
      {
        query: SettingsQuery,
      },
    ],
  };

  return client.mutate(request)
  .then(({data, error}) => ({ data, error }))
  .catch((error) => ({ error }));
}
