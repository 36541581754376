import { navigate} from '@reach/router';

import { requestMandateRemote, deactivateBilling } from '../api/mandate';
import { onError } from './errors';

export const mandateEffects = {
  requestMandate: () => async ({state, setState}) => {

    const { client } = state; 
    const { data, error } = await requestMandateRemote(client);

    if (error) {
      const { auth } = state;
      onError(error, auth);  
    } else {
      const { requestMandate } = data;
      setState({redirectUrl: requestMandate}); 
    }
  },
  deactivateBilling: (account, effectiveDate) => async ({state, setState}) => {

    const { client } = state; 
    const { data, error } = await deactivateBilling(client, account, effectiveDate);

    if (error) {
      const { auth } = state;
      onError(error, auth);  
    } else {
      navigate('/app/billing');
    }

  },
};
