import React, { Component } from 'react';
import { Query } from 'react-apollo';
import moment from 'moment';

import { Container } from 'constate';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Spinner from 'react-spinkit';

import Layout from '../../components/layout';

import Error from '../../components/widgets/error';

import { SettingsQuery } from '../../app/graph/queries/SettingsQuery';

import { mandateEffects } from '../../app/effects/mandate';

class CancelBilling extends Component {

  constructor(props) {
    super(props);
    const startOfNextDay = moment().startOf('day').add(1, 'days');
    this.state = {
      requested: false,
      confirmed: false,
      effectiveDate: startOfNextDay,
      minimumDate: startOfNextDay,
    };
  }

  handleChange = (date) => {
    this.setState({
      effectiveDate: date
    });
  }

  onDeactivate = () => {
    const dateFormat = this.state.effectiveDate.format('YYYY-MM-DD');
    let { deactivateBilling, account } = this.props;
    deactivateBilling(account.id, dateFormat);
  }

  renderCancelled = (endDate) => {

    let { account } = this.props;
    let formatted = endDate.format('DD/MM/YYYY');

    const future = endDate.isAfter(moment());

    if (future) {
      return (
        <div>
          <h1>Billing Cancellation</h1>
          <div>The billing for account {account.id} will be cancelled on {formatted}.</div>
        </div>
      );
    } else {
      return (
        <div>
          <h1>Billing Cancelled</h1>
          <div>The billing for account {account.id} was cancelled on {formatted}.</div>
        </div>
      );
    }
  }

  componentWillReceiveProps(nextProps) {

    let { account } = nextProps;
    let { start_date, end_date } = account.billing;
    const startDate = moment(start_date);

    const minimumDate = startDate.add(1, 'days');
    const effectiveDate = minimumDate;

    this.setState({
      minimumDate,
      effectiveDate,
    });
  }


  render() {

    let { account } = this.props;
    let { end_date } = account.billing;

    let { confirmed, requested, effectiveDate, minimumDate } = this.state;

    if (end_date) {
      const endDate = moment(end_date);
      return this.renderCancelled(endDate);
    } else {

      const dateFormat = effectiveDate.format('DD/MM/YYYY');

      return (
        <div className='row pb4'>
          <div className="col-xs-1 col-sm-1 col-md-2 col-lg-2"></div>
          <div className="col-xs-10 col-sm-10 col-md-2 col-lg-6">

            <h1>Cancel Billing</h1>

            <div>Cancelling the billing means that telephony services will be stopped.</div>

            {!requested &&

              <div className='pt3'>
                <h3>Cancellation Date</h3>

                <div>

                  <DatePicker className='outline pa1'
                    locale='en-GB'
                    minDate={minimumDate.toDate()}
                    selected={effectiveDate.toDate()}
                    onChange={this.handleChange} />

                </div>

                <button className='mt3 bg-yellow'>
                  <div onClick={() => this.setState({ requested: true })}>
                    Cancel Payments
                </div>
                </button>

              </div>

            }

            {requested && !confirmed &&
              <div className='pt3'>
                <h3>Confirm Cancellation</h3>
                <div>Are you sure you want services to stop on {dateFormat}?</div>

                <button className='mt3 bg-yellow'>
                  <div onClick={this.onDeactivate}>
                    Yes, I am sure
                </div>
                </button>
              </div>
            }

          </div>
        </div>
      );
    }
  }
}

const CancelPage = () => (
  <Layout>
    <Container
      context='primary'
      effects={mandateEffects}
      >
      {({deactivateBilling}) => (
        <Query query={SettingsQuery}>
          {({ loading, error, data }) => {
            // TOOD Be more defensive, extract this into its own component ....
            if (loading) {
              return <Spinner name="line-scale" />
            } else if (error) {
              return (<Error error={error}/>);
            } else if (data) {
              return (
                <CancelBilling
                  deactivateBilling={deactivateBilling}
                  account={data.profile.accounts[0]} />);
            }
          }}
        </Query>
      )}
    </Container>
  </Layout>
)

export default CancelPage;